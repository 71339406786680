<template>
	<div class="buy">
		<div class="head">
			<img src="../assets/head.png">
			<van-notice-bar
					
					left-icon="volume-o"
					text="输入提货券信息领取福利~"
			/>
		</div>
		<div class="step" v-if="step1">
			<van-form @submit="onSubmit">
				<van-field
						v-model="key"
						name="key"
						label="券号"
						autocomplete="off"
						placeholder="请输入"
						:rules="[{ required: true, message: '10位数字提货券号' }]"
				/>
				<van-field
						v-model="password"
						name="password"
						autocomplete="off"
						label="卡密"
						placeholder="请输入"
						:rules="[{ required: true, message: '刮开后获得卡密' }]"
				/>
				<van-field name="shipping" label="配送方式">
					<template #input>
						<van-radio-group v-model="shipping" direction="horizontal">
							<van-radio name="shunfeng">包邮到家</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field
						center
						v-model="vcode"
						name="captcha"
						autocomplete="off"
						label="验证码"
						placeholder="请输入"
						:rules="[{ required: true, message: '右侧验证码' }]"
				>
					<template #right-icon>
						<van-image :src="imageSrc" width="80" height="40" @click="_updatePicCode" />
					</template>
				</van-field>
				<div style="margin: 16px;">
					<van-button round block type="info" native-type="submit">验证</van-button>
				</div>
			</van-form>
		</div>
		<div class="step" v-if="step2">
			<van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">选择套餐</van-divider>
				<div v-for="(item,index) in goodslist">
					<van-radio-group v-model="goods_type_id">
					  <van-cell-group>
					    <van-cell :title="item.name" clickable @click="goods_type_id = item.id" class="item-title">
							<!-- <van-button type="info" size="mini" class="goodsbtn" clickable @click="goodsdetail(item.id)">查看套餐</van-button> -->
					      <template #right-icon>
					        <van-radio :name="item.id" />
					      </template>
					    </van-cell>
						<div v-for="(item2,index2) in item.goodsList" class="goodslist">
							 <p class="item-name">{{item2.name}}</p>
							 <p class="item-num">x{{item2.number}}</p>
						</div>
					  </van-cell-group>
					</van-radio-group>
				</div>
			<van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">填写收货人</van-divider>
			<van-address-edit
					:area-list="areaList"
					@save="onSave"
					save-button-text="确认提货"
					detail-rows="2"
					:area-columns-placeholder="['请选择', '请选择', '请选择']"
			/>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import {Form, Image as VanImage} from 'vant';
	import { Field } from 'vant';
	import { Button } from 'vant';
	import { NoticeBar } from 'vant';
	import { AddressEdit } from 'vant';
	import areaList from '../assets/areaList.js';
	import { Dialog } from 'vant';
	import { RadioGroup, Radio } from 'vant';
	import { Cell, CellGroup } from 'vant';
	import { Divider } from 'vant';
	import { Card } from 'vant';
	Vue.use(Divider);
	Vue.use(Cell);
	Vue.use(CellGroup);
	Vue.use(Radio);
	Vue.use(RadioGroup);
	Vue.use(Dialog);
	Vue.use(AddressEdit);
	Vue.use(NoticeBar);
	Vue.use(Form);
	Vue.use(Field);
	Vue.use(Button);
	Vue.use(Card);
export default {
	components:{
		[VanImage.name]: VanImage,
	},
	name: 'buy',
	data() {
		return {
			key: '',
			tel: '',
			password: '',
			stepNum:0,
			imageSrc: '',
			vcode: '',
			step1:true,
			step2:false,
			searchResult: [],
			areaList:areaList,
			shipping: 'shunfeng',
			goods_type_id:1,
			step1data:'',
			goodslist:[]
		};
	},
	methods: {
		_updatePicCode() {
			this.imageSrc = 'https://coupon.zsh.gongxiaoyi.com/index/verify?d=' + Math.random();
		},
    _initGoods(key){
      const that = this
      this.$axios({
        method: "post",//指定请求方式
        url: "index/init",
        data: { coupon_code: key }
      }).then(function(res){
        let data = JSON.parse(res.data);
        if (data.code == 1){
          that.goodslist = data.data;
        }else{
          Dialog.alert({
            title: '获取基础数据失败',
            message: data.msg,
          })
        }
        //接口成功返回结果执行
      }).catch(function(){
        //请求失败或者接口返回失败或者.then()中的代码发生错误时执行
      })
    },
		created() {
			this.$nextTick(() => this._updatePicCode())
		},
		// 验证接口
		onSubmit(values) {
			this.step1data = values;
			const that = this
      that._initGoods(that.key);
			// demo 跳过验证
			// that.step1 = false;
			// that.step2 = true;
			// that.stepNum = 1;
			// return;
			// demo
		this.$axios({
				method: "post",//指定请求方式
				url: "index/checkCoupon",//请求接口（相对接口，后面会介绍到）
				data: values
			}).then(function(res){
				let data = JSON.parse(res.data);
				if (data.code == 1){
						Dialog.alert({
							title: '验证通过',
							message: data.msg,
						}).then(() => {
							that.step1 = false;
							that.step2 = true;
							that.stepNum = 1;
						});
				}else{
					Dialog.alert({
						title: '验证失败',
						message: data.msg,
					}).then(() => {
						// 重新获取验证码
						that._updatePicCode();
					});
				}
				//接口成功返回结果执行
			}).catch(function(err){
				//请求失败或者接口返回失败或者.then()中的代码发生错误时执行
			})
		},
		// 将两个json对象合并
		twoJsonMerge(json1,json2){
			var length1 = 0,length2 = 0,jsonStr,str;
			for(var ever in json1) length1++;
			for(var ever in json2) length2++;
			if(length1 && length2)str = ',';
			else str = '';
			jsonStr = ((JSON.stringify(json1)).replace(/,}/,'}') + (JSON.stringify(json2)).replace(/,}/,'}')).replace(/}{/,str);
			return JSON.parse(jsonStr);
		},
		// 确认提货
		onSave(values) {
			this.step1data.goods_type_id = this.goods_type_id;
			var all = this.twoJsonMerge(this.step1data, values);
			Dialog.confirm({
				title: '是否确认',
				message: '确认？',
			}).then(() => {
				this.$axios({
					method: "post",//指定请求方式
					url: "index/useCoupon",//请求接口（相对接口，后面会介绍到）
					data: all
				}).then(function(res){
					let data = JSON.parse(res.data);
					if (data.code == 1){
						Dialog.alert({
							title: '提货成功,等待配送',
							message: data.msg,
						}).then(() => {
							location.reload()
						});
					}else{
						Dialog.alert({
							title: '提货失败',
							message: data.msg,
						}).then(() => {

						});
					}
					//接口成功返回结果执行
				}).catch(function(){
					//请求失败或者接口返回失败或者.then()中的代码发生错误时执行
				})
			}).catch(() => {
				// on cancel
			});

		},
	},
	mounted:function () {
		this._updatePicCode();
	}
}
</script>

<style scoped>
	.step-buy{
		width: 80%;
			position: absolute;
			left: 50%;
			transform: translate(-50%,-50%);
	}
	.head{
		text-align: center;
		margin: 10px 0 50px 0;
	}

	.head img{
		width: 90%;
		margin: 10px 0 30px 0;
		/*padding: 0 20px 0 20px;*/
	}
	.step{
		margin:10px 0 0 0;
	}
	.goodsbtn{
		margin-right: 15px;
	}
	.goodslist{
		//height: 60px;
	}
	.van-card__thumb{
		height: 50px;
		width: 50px;
	}
	.van-card__content{
		min-height: unset;
	}
  .item-title{
    color: #ed6a0c;
  }
  .item-name{
    margin-left: 5%;
    font-size: 14px;
    color: #9b9b9b;
    display: inline;
  }
  .item-num{
    color: #9b9b9b;
    display: inline;
    float: right;
    margin: 0;
    margin-right: 3%;
  }
</style>
